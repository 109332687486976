































































import { SfSection, SfImage } from "@storefront-ui/vue";
import { defineComponent, ref } from "@nuxtjs/composition-api";
import VueSlickCarousel from "vue-slick-carousel";
import { Icon } from "@iconify/vue2";
import axios from "axios";

export default defineComponent({
  name: "InstagramFeed",
  components: {
    SfSection,
    SfImage,
    VueSlickCarousel,
    Icon,
  },
  props: ["blok"],
  data() {
    return {
      slickOptions: {
        dots: false,
        arrows: true,
        autoplay: false,
        autoplaySpeed: 4000,
        prevArrow: null,
        nextArrow: null,
        pauseOnHover: false,
        infinite: true,
        slidesToShow: 4,
        initialSlide: 1,
        slidesToScroll: 1,
        vertical: false,
        focusOnSelect: true,
        draggable: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              arrows: true,
              dots: false,
              centerPadding: "50px",
            },
          },
        ],
      },
      mounted: false,
    };
  },
  setup() {
    const instafeed = ref();

    return {
      instafeed,
    };
  },
  methods: {
    async refreshLongLivedToken() {
      try {
        const response = await axios.get(
          `https://graph.instagram.com/refresh_access_token?grant_type=ig_refresh_token&access_token=${process.env.VSF_INSTA_FEED}`
        );
      } catch (error) {
        console.error(
          "Error refreshing token:",
          error.response ? error.response.data : error.message
        );
      }
    },
    async getInsta() {
      try {
        await this.refreshLongLivedToken();
        const response = await axios.get(
          `https://graph.instagram.com/me/media?fields=id,caption,thumbnail_url,media_type,media_url,permalink&access_token=${process.env.VSF_INSTA_FEED}`
        );
        this.instafeed = response.data.data;
      } catch (error) {
        console.error("Error fetching Instagram data:", error);
      }
    },
  },
  mounted() {
    this.getInsta();
  },
});
